[data-tenant='rac'] {
  /* Rac Grey color */
  --color-rac-grey-10: #ededed;
  --color-rac-grey-20: #dbdbdb;
  --color-rac-grey-50: #a3a3a6;
  --color-rac-grey-70: #808080;
  --color-rac-grey-100: #47474a;

  /* Rac Orange color */
  --color-rac-orange-10: #ffede5;
  --color-rac-orange-20: #ffdbcf;
  --color-rac-orange-50: #fca885;
  --color-rac-orange-70: #fa8552;
  --color-rac-orange-100: #f95108;

  /* Rac Blue color */
  --color-rac-blue-10: #e1e8eb;
  --color-rac-blue-20: #d6e0e8;
  --color-rac-blue-50: #6d9db1;
  --color-rac-blue-70: #3e7d9e;
  --color-rac-blue-100: #005e8d;

  --color-primary-50: var(--color-rac-grey-10);
  --color-primary-100: var(--color-rac-grey-10);
  --color-primary-200: var(--color-rac-orange-20);
  --color-primary-300: var(--color-rac-orange-50);
  --color-primary-400: var(--color-rac-orange-70);
  --color-primary-500: var(--color-rac-orange-100);
  --color-primary-600: var(--color-rac-orange-100);
  --color-primary-700: var(--color-rac-orange-100);

  --color-primary-800: lch(61 85 47.59);
  --color-primary-900: lch(59 85 47.59);
  --color-secondary-100: var(--color-rac-orange-10);
  --color-secondary-500: var(--color-rac-grey-100);

  --color-tertiary-50: initial;

  --color-mica-blue-500: var(--color-primary-500);

  /* Rac App */
  --footer-background-color: var(--color-rac-grey-100);

  /* Gds components */

  /* header*/
  --header-nav-background-color: #fff;
  --header-nav-item-color: #000;
  --header-nav-item-hover-bg: transparent;
  --header-nav-item-active-bg: transparent;
  --header-nav-counter-bg: var(--color-primary-500);
  --header-nav-fav-icon-bg: var(--color-primary-500);

  /* Logo */

  --fill-primary-color: var(--color-primary-500);
  --fill-primary-undermark-color: #555;

  /* Icons */

  --icon-primary-color: var(--color-rac-orange-100);

  /* Global Border */
  --border-radius: var(--spacing-1);

  /* Button */
  --button-primary-base-bg-color: var(--color-rac-orange-100);
  --button-primary-hover-bg-color: var(--color-primary-800);
  --button-primary-active-bg-color: var(--color-primary-900);

  /* Form */
  --form-control-base-border-color: var(--color-rac-grey-50);
  --form-control-hover-border-color: var(--color-rac-grey-70);
  --form-control-focused-border-color: var(--color-rac-grey-70);
  --form-control-filled-border-color: var(--color-rac-grey-50);
  --form-control-label-color: var(--color-rac-grey-100);
  --form-control-hint-color: var(--color-rac-grey-100);
  --form-control-icon-color: var(--color-rac-grey-100);

  color: var(--color-rac-grey-100);
}

p a {
  color: inherit;
  transition: 0.1s;
}

p a:hover {
  color: var(--color-primary-500);
}

p a {
  color: inherit;
  transition: 0.1s;
}

p a:hover {
  color: var(--color-primary-500);
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/3fcd33a27296ff83-s.p.woff2) format('woff2');
font-display: block;
font-weight: 100;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/3fcd33a27296ff83-s.p.woff2) format('woff2');
font-display: block;
font-weight: 400;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/3fcd33a27296ff83-s.p.woff2) format('woff2');
font-display: block;
font-weight: normal;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/9c496f57d16399a3-s.p.woff2) format('woff2');
font-display: block;
font-weight: 500;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/eb5c97377ced185c-s.p.woff2) format('woff2');
font-display: block;
font-weight: 600 700;
}

@font-face {
font-family: '__objektiv_516fde';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/6e2115458c57af1d-s.p.woff2) format('woff2');
font-display: block;
font-weight: bold;
}@font-face {font-family: '__objektiv_Fallback_516fde';src: local("Arial");ascent-override: 103.86%;descent-override: 27.93%;line-gap-override: 0.00%;size-adjust: 109.19%
}.__className_516fde {font-family: '__objektiv_516fde', '__objektiv_Fallback_516fde', Objektiv, sans-serif
}.__variable_516fde {--font-family-system: '__objektiv_516fde', '__objektiv_Fallback_516fde', Objektiv, sans-serif
}

@font-face {
font-family: '__FrederickSimms_6a1dd5';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/876320287ef23a86-s.p.woff2) format('woff2');
font-display: block;
font-weight: 200;
}

@font-face {
font-family: '__FrederickSimms_6a1dd5';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/30d4879356d7cef4-s.p.woff2) format('woff2');
font-display: block;
font-weight: 400;
}

@font-face {
font-family: '__FrederickSimms_6a1dd5';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/f666b0adcc6e130e-s.p.woff2) format('woff2');
font-display: block;
font-weight: 500;
}@font-face {font-family: '__FrederickSimms_Fallback_6a1dd5';src: local("Arial");ascent-override: 90.15%;descent-override: 23.32%;line-gap-override: 32.87%;size-adjust: 92.18%
}.__className_6a1dd5 {font-family: '__FrederickSimms_6a1dd5', '__FrederickSimms_Fallback_6a1dd5', Objektiv, sans-serif
}.__variable_6a1dd5 {--font-family-system: '__FrederickSimms_6a1dd5', '__FrederickSimms_Fallback_6a1dd5', Objektiv, sans-serif
}

@font-face {
font-family: '__dinRoundOT_1142e2';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/b9bf302b570333c7-s.p.woff2) format('woff2');
font-display: block;
font-weight: 500;
}

@font-face {
font-family: '__dinRoundOT_1142e2';
src: url(https://web.assets.prod.heycar.com/v6.436.1/_next/static/media/42aaa0e3eb595d00-s.p.woff2) format('woff2');
font-display: block;
font-weight: normal;
}@font-face {font-family: '__dinRoundOT_Fallback_1142e2';src: local("Arial");ascent-override: 99.01%;descent-override: 25.24%;line-gap-override: 0.00%;size-adjust: 99.42%
}.__className_1142e2 {font-family: '__dinRoundOT_1142e2', '__dinRoundOT_Fallback_1142e2', Objektiv, sans-serif
}.__variable_1142e2 {--font-family-system: '__dinRoundOT_1142e2', '__dinRoundOT_Fallback_1142e2', Objektiv, sans-serif
}

